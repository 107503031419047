.mainContainer {
  background-color: #1d1d1d;
  color: white;
  padding-block: 100px;
}

.cardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardInfo svg {
  font-size: 35px;
  margin-bottom: 15px;
}

.cardInfo h4 {
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}

.cardInfo h5 {
  font-size: 20px;
  font-weight: 300;
  color: #b5bbe0;
}

.cardInfo p {
  max-width: 280px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
  color: #b3b3b3;
}

@media screen and (max-width: 768px) {
  .mainContainer {
    padding-block: 50px;
  }

  .cardInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .cardInfo p {
    max-width: 380px;
    text-align: center;
  }
}
