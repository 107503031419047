.heroSection {
  width: 100%;
  position: absolute;
  top: 0;
}

/* sections */
.aboutUs {
  margin-top: 530px;
}

.fundamentals,
.whyUs,
.faq {
  margin-top: 100px;
}

@media screen and (max-width: 767px) {
  .aboutUs {
    margin-top: 450px;
  }
}
