.heroSection {
  position: absolute;
  top: 0;
  width: 100%;
}

.heroContainer {
  min-height: 900px;
  background-image: url("../../../Assets/images/bg-hero-home.jpg");
  background-size: cover;
  background-position: center;
  padding-top: calc(var(--nav-bar-height) + 50px);
}

.heroContent {
  padding: 52px;
  color: white;
}

.heroContent h1 {
  font-weight: 400;
  font-family: "Playfair Display", serif;
  font-size: 64px;
  position: relative;
  margin-bottom: 55px;
}

.heroContent h1::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 55px;
  height: 3px;
  background-color: white;
}

.heroContent p.subTitle {
  font-size: 18px;
  max-width: 750px;
  line-height: 35px;
}

.ctaButton {
  display: inline-flex;
  align-items: center;
  padding: 10px 16px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: white;
  border-radius: var(--border-radius);
  color: var(--main-color);
  transition: var(--fast-transition) ease;
  margin-top: 35px;
}

.ctaButton:hover {
  background-color: var(--main-color);
  color: white;
}

.ctaButton svg {
  margin-right: 4px;
  font-size: 20px;
  margin-bottom: 2px;
}

.quotationBox {
  margin-inline: auto;
  max-width: calc(100% - 104px);
  border-radius: var(--border-radius);
  margin-top: -80px;
  background-color: var(--secondary-bg-color);
  overflow: hidden;
}

.aboutUs {
  padding: 52px 60px;
}

.aboutUs h4 {
  color: white;
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 25px;
  font-family: "Playfair Display", serif;
}

.aboutUs p {
  color: var(--secondary-color);
  font-weight: 400;
  line-height: 1.8;
  font-size: 16px;
  margin-bottom: 30px;
  max-width: 450px;
}

.aboutUs h3 {
  color: white;
  font-weight: 400;
  letter-spacing: 2.4px;
  line-height: 1.8;
  font-size: 28px;
  font-family: "Great Vibes";
}

.aboutUs h6 {
  color: white;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1.8;
  font-size: 16px;
}

.aboutUsImg {
  background-image: url("../../../Assets/images/bg-about-us-home.jpg");
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 991px) {
  .aboutUs {
    text-align: center;
  }
  .aboutUs p {
    text-align: center;
    max-width: 80%;
    margin-inline: auto;
  }
  .aboutUsImg {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .aboutUs {
    padding: 32px 24px;
  }
  .aboutUs p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .heroContainer {
    min-height: 800px;
    padding-top: calc(var(--nav-bar-height) - 80px);
  }
}

@media screen and (max-width: 576px) {
  .heroContent {
    padding: 24px;
    color: white;
  }

  .heroContent h1 {
    font-size: 48px;
  }

  .heroContent p.subTitle {
    font-size: 16px;
    line-height: 30px;
  }

  .aboutUs {
    padding: 32px 24px;
  }

  .aboutUs p {
    text-align: center;
    max-width: 100%;
    margin-inline: auto;
  }

  .quotationBox {
    max-width: calc(100%);
  }
}

@media screen and (max-width: 330px) {
  .heroContent h1 {
    font-size: 34px;
  }
}
