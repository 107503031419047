header {
  position: relative;
  z-index: 3;
}

.topNavContainer {
  display: flex;
  justify-content: space-between;
  padding-block: 50px;
}

.logoContainer {
  position: relative;
  z-index: 6;
  cursor: pointer;
}

.infoBoxesContainer {
  display: flex;
}

.infoBox {
  display: flex;
  margin-right: 15px;
  color: white;
}

.infoBox svg {
  font-size: 26px;
  color: var(--secondary-color);
  margin-right: 10px;
}

.infoBox h5 {
  font-weight: 600;
  margin-bottom: 4px;
}

.headerButton {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: white;
  border-radius: var(--border-radius);
  color: var(--main-color);
  transition: var(--fast-transition) ease;
}

.headerButton:hover {
  background-color: var(--main-color);
  color: white;
}

.headerButton svg {
  margin-right: 4px;
  font-size: 20px;
  margin-bottom: 2px;
}

.navBarIconMobile {
  display: none;
}

.iconPartOne {
  width: 30px;
  height: 3px;
  border-radius: 10px;
  margin-bottom: 7px;
  background-color: white;
  transition: all var(--fast-transition);
}

.navBarIconMobile.active .iconPartOne {
  width: 30px;
  transform: translateY(10px) rotate(-45deg);
}

.iconPartTwo {
  width: 22px;
  height: 3px;
  border-radius: 10px;
  background-color: white;
  opacity: 1;
  transition: all var(--fast-transition);
}

.navBarIconMobile.active .iconPartTwo {
  opacity: 0;
}

.iconPartThree {
  width: 14px;
  height: 3px;
  border-radius: 10px;
  background-color: white;
  margin-top: 7px;
  transition: all var(--fast-transition);
}

.navBarIconMobile.active .iconPartThree {
  width: 30px;
  transform: translateY(-10px) rotate(45deg);
}

.mobileMenu {
  display: none;
  position: fixed;
  background-color: #000000d6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition: all var(--fast-transition);
  transform: translateY(-100%);
}

.mobileMenu nav {
  margin-top: 20px;
  height: calc(100% - 95px);
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 70px;
}

.menuIconsContainer {
  text-align: center;
  flex-grow: 1;
  margin-bottom: 41px;
}

.menuIconsContainer .menuIcon {
  height: 30px;
  margin: 0 20px;
}

.mobileMenuOpen {
  transform: translateY(0);
}

.bodyScrollDisabled {
  overflow: unset;
}

@media screen and (max-width: 1090px) {
  .infoBox {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .headerButton {
    display: none;
  }

  .bodyScrollDisabled {
    overflow: hidden;
  }

  .navBarIconMobile {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;
    width: 30px;
    text-align: right;
    cursor: pointer;
    z-index: 110;
  }

  .navBarMenuItem,
  .navBarMenuItemActive {
    font-weight: 600;
    font-size: 18px;
    margin: 50px 0;
    position: relative;
  }

  .navBarMenuItem a,
  .navBarMenuItemActive a {
    font-weight: 600;
    color: white;
    font-size: 24px;
  }

  .mobileMenu {
    display: block;
  }
}

@media screen and (max-width: 330px) {
  .logoContainer img {
    height: 35px;
  }
}

/* keyframes change opacity */
@keyframes changeOpacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
