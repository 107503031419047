.areas {
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 40px;
}
.list {
  height: fit-content;
  padding-top: 75px;
  background-color: #e9e9e9;
}

.list ul {
  list-style: none;
}

.list ul li {
  padding: 14px 0;
  border-bottom: 1px solid var(--gray);
}
.list ul li::before {
  border-left: 3px solid var(--gray);
  height: 1.75em;
  content: " ";
  display: inline-block;
  vertical-align: middle;
  transition: border-color 300ms;
  -moz-transition: border-color 300ms;
  -o-transition: border-color 300ms;
  -webkit-transition: border-color 300ms;
}
.list ul li:hover::before {
  border-color: var(--blue);
}

.list ul li a {
  display: inline-block;
  padding-left: 15px;
  border-left: 0;
  vertical-align: middle;
  color: var(--strong-gray);
  text-decoration: none;
}
.list ul li a::before {
  content: "\27A1";
  font-size: 15px;
  padding-right: 12px;
  margin-left: -26px;
  opacity: 0;
  transition: opacity 200ms, margin-left 200ms;
  -moz-transition: opacity 200ms, margin-left 200ms;
  -o-transition: opacity 200ms, margin-left 200ms;
  -webkit-transition: opacity 200ms, margin-left 200ms;
}
.list ul li a:hover::before {
  opacity: 1;
  margin-left: 0;
}

.list ul li a:hover {
  color: var(--blue);
}

.list ul li.active::before {
  border-color: var(--blue);
}
.list ul li.active a::before {
  opacity: 1;
  margin-left: 0;
}
.description {
  padding-top: 75px;
}

.description h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .areas {
    grid-template-columns: 1fr;
  }
}
