.containerStyle {
  padding-inline: 52px;
}

.buttonCommon {
  padding: 16px 25px 18px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: white;
}

.buttonCommon:hover {
  background-color: var(--main-color);
  color: white;
}

.buttonCommonDisabled {
  opacity: 0.6;
}

.buttonCommonDisabled:hover {
  opacity: 0.6;
  color: var(--main-color);
  background-color: white;
}

.inputFieldStyle {
  background-color: #f5f5f5;
  padding: 13px 15px;
  border-radius: 2px;
  border: 1px solid transparent;
}

.inputFieldErrorStyle {
  background-color: #ffcece;
  border: 1px solid rgb(255, 87, 87);
  padding: 13px 15px;
  border-radius: 2px;
}

.inputFieldSuccessStyle {
  background-color: #f5f5f5;
  padding: 13px 15px;
  border-radius: 2px;
  border: 1px solid transparent;
}

/* Loader */
.ldsRing {
  margin: auto;
  position: relative;
  width: 23px;
  height: 23px;
}
.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 23px;
  height: 23px;
  border: 2px solid white;
  border-radius: 50%;
  animation: ldsRing 1.1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: white transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loader End */

@media screen and (max-width: 768px) {
  .containerStyle {
    padding-inline: 20px;
  }
}
