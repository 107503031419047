.containerForm {
  background-color: #f0f0f0;
  padding: 60px 0px;
}

.mainContainer {
  width: 70%;
  background-color: white;
  margin: auto;
  box-shadow: 0 20px 28px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0 20px 28px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 20px 28px rgb(0 0 0 / 10%);
  border-radius: 6px 6px 6px 6px;
  -moz-border-radius: 6px 6px 6px 6px;
  -webkit-border-radius: 6px 6px 6px 6px;
  padding: 60px 100px;
}

.header {
  text-align: center;
  position: relative;
  margin-bottom: 35px;
}

.header h6 {
  font-size: 17px;
  font-weight: 400;
  color: #a0a0a0;
  margin-top: 15px;
  max-width: 500px;
  margin: 0 auto;
  line-height: 1.6;
}

.header svg {
  margin-left: 5px;
}

.header h3 {
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}

.textArea {
  background-color: #f5f5f5;
  padding: 13px 15px;
  border-radius: 2px;
  border: none;
  width: 100% !important;
}

textarea,
textarea::placeholder {
  outline: none !important;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.2px;
  padding: 6px 10px;
  caret-color: blue;
  color: gray;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.buttonEnabled {
  background-color: var(--main-color);
  color: white;
  padding: 16px 25px;
  text-align: center;
  transition: 0.5s all;
}

.buttonEnabled:hover {
  background-color: var(--main-color);
  color: white;
  padding: 16px 25px;
  text-align: center;
  transition: 0.5s all;
}

.buttonDisabled {
  background-color: #43489894;
  cursor: not-allowed;
  color: white;
  padding: 16px 25px;
  text-align: center;
  transition: 0.5s all;
}

.buttonDisabled:hover {
  background-color: #43489894;
  cursor: not-allowed;
  color: white;
  padding: 16px 25px;
  text-align: center;
  transition: 0.5s all;
}

/* Check status animation */
.animationCheckContainer {
  text-align: center;
}

.animationCheckContainer p {
  color: #525252;
  max-width: 300px;
  margin: auto;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

@media screen and (max-width: 1199px) {
  .mainContainer {
    width: 90%;
    padding: 60px 60px;
  }
}

@media screen and (max-width: 991px) {
  .mainContainer {
    width: 90%;
    padding: 60px 40px;
  }
  .header h3 {
    font-size: 26px;
  }
  .header h6 {
    font-size: 16px;
  }
}

@media screen and (max-width: 460px) {
  .mainContainer {
    width: 100%;
    padding: 60px 15px;
  }
  .header h3 {
    font-size: 26px;
  }
  .header h6 {
    font-size: 16px;
  }
}
