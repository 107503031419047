.loaderContainer {
  height: 100vh;
  width: 100%;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  animation: fadeOut 0.5s ease-in-out 0.5s forwards;
}

.loader {
  position: relative;
  width: 120px;
  height: 140px;
  background-image: radial-gradient(circle 30px, #fff 100%, transparent 0),
    radial-gradient(circle 5px, #fff 100%, transparent 0),
    radial-gradient(circle 5px, #fff 100%, transparent 0),
    linear-gradient(#fff 20px, transparent 0);
  background-position: center 127px, 94px 102px, 16px 18px, center 114px;
  background-size: 60px 60px, 10px 10px, 10px 10px, 4px 14px;
  background-repeat: no-repeat;
  z-index: 10;
  perspective: 500px;
}
.loader::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%) rotate(-45deg);
  border-right-color: transparent;
  box-sizing: border-box;
}
.loader::after {
  content: "";
  position: absolute;
  height: 80px;
  width: 80px;
  transform: translate(-50%, -55%) rotate(-45deg) rotateY(0deg);
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  border: 7px solid #0099ff;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

.bodyScrollDisabled {
  overflow: hidden;
}

/* fade out keyframes */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: 0;
  }
}

@keyframes rotate {
  to {
    transform: translate(-50%, -55%) rotate(-45deg) rotateY(360deg);
  }
}
