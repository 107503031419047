.mainContainer {
  background-color: #1d1d1d;
  color: white;
}

.footerContainer {
  padding-block: 60px;
}

.bottomFooter {
  background-color: rgb(0, 0, 0);
  height: 100px;
}

.logoColumn img {
  width: 200px;
}

.logoColumn p {
  max-width: 80%;
  margin-top: 20px;
  line-height: 1.7;
  color: #ababab;
}

.siteMapColumn h4 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  text-transform: none;
  font-size: 18px;
  margin-block: 8px 38px;
}

.siteMapColumn ul li {
  color: #ababab;
  line-height: 1.7;
  cursor: pointer;
  margin-bottom: 5px;
  position: relative;
}

.siteMapColumn ul li span {
  position: relative;
}

.siteMapColumn ul li span::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all var(--fast-transition) ease;
  background-color: var(--nav-link-color);
}

.siteMapColumn ul li span:hover::after {
  width: 100%;
  opacity: 1;
}

.contactInfo h4 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  text-transform: none;
  font-size: 18px;
  margin-block: 8px 38px;
}

.contactInfo ul li {
  color: #ababab;
  line-height: 1.7;
  margin-bottom: 5px;
}

.contactInfo ul li span {
  color: #cfcfcf;
  margin-right: 4px;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 5px;
}

.bottomFooter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomFooter p {
  display: flex;
  align-items: center;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .footerContainer {
    text-align: center;
  }

  .logoColumn p {
    margin: auto;
    margin-top: 10px;
  }

  .siteMapColumn h4,
  .contactInfo h4 {
    margin-block: 8px;
  }

  .bottomFooter p {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

@media screen and (max-width: 330px) {
  .bottomFooter p {
    display: flex;
    align-items: center;
    font-size: 10px;
  }
}