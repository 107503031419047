.heroSection {
  width: 100%;
  position: absolute;
  top: 0;
}

/* sections */
.servicesGrid {
  margin-top: 1100px;
}

.teamSlider {
  margin-top: 100px;
}

.aboutUsRow {
  margin-top: 100px;
}

.contactForm {
  margin-top: 100px;
}

@media screen and (max-width: 991px) {
  .servicesGrid {
    margin-top: 1100px;
  }
  .teamSlider,
  .aboutUsRow,
  .contactForm {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .servicesGrid {
    margin-top: 1050px;
  }
}

@media screen and (max-width: 660px) {
  .servicesGrid {
    margin-top: 1100px;
  }
}

@media screen and (max-width: 575px) {
  .servicesGrid {
    margin-top: 1000px;
  }
}

@media screen and (max-width: 435px) {
  .servicesGrid {
    margin-top: 1050px;
  }
}

@media screen and (max-width: 391px) {
  .servicesGrid {
    margin-top: 1050px;
  }
}

@media screen and (max-width: 330px) {
  .servicesGrid {
    margin-top: 1100px;
  }
}

@media screen and (max-width: 320px) {
  .servicesGrid {
    margin-top: 1150px;
  }
}
