.header {
  text-align: center;
  position: relative;
}

.header h6 {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--light-gray);
  margin-bottom: 15px;
}

.header h3 {
  margin-bottom: 75px;
  font-family: "Playfair Display", serif;
}

.header::after {
  content: "";
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  width: 55px;
  height: 3px;
  background-color: var(--gold);
}

.cardsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}

.cardContainer {
  text-align: center;
  border-radius: var(--border-radius);
  padding: 55px 30px;
  box-shadow: 0px 10px 30px rgb(10 10 10 / 10%);
  -moz-box-shadow: 0px 10px 30px rgba(10, 10, 10, 0.1);
  -webkit-box-shadow: 0px 10px 30px rgb(10 10 10 / 10%);
  transition: all var(--fast-transition) ease-in-out;
}

.cardContainer:hover {
  transform: translate(0, -20px);
  background-color: var(--main-color);
}

.cardContainer:hover h4,
.cardContainer:hover a {
  color: white;
}

.cardContainer:hover p {
  color: white;
  opacity: 0.6;
}

.cardContainer img {
  margin-bottom: 15px;
}

.cardContainer h4 {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0px;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
  transition: all var(--fast-transition) ease-in-out;
}

.cardContainer p {
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  color: var(--strong-gray);
  max-width: 300px;
  margin-inline: auto;
  line-height: 1.7;
  transition: all var(--fast-transition) ease-in-out;
}

.cardContainer a {
  color: var(--gold);
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all var(--fast-transition) ease-in-out;
}

.cardContainer a svg {
  margin-left: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  color: #3a3e7f;
  font-size: 18px;
  font-weight: 600;
}

.pagination div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7e7e7;
  width: 60px;
  height: 60px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.cardsGridMobile {
  display: none;
}

@media screen and (max-width: 1199px) {
  .cardsGrid {
    grid-gap: 20px;
  }
}

@media screen and (max-width: 991px) {
  .cardsGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .cardsGrid {
    display: none;
  }
  .cardsGridMobile {
    display: block;
  }

  .cardContainer {
    white-space: break-spaces;
    border: 1px solid rgba(0, 0, 0, 0.088);
    box-shadow: 2px 14px 10px -7px rgb(10 10 10 / 31%);
    -moz-box-shadow: 2px 14px 10px -7px rgb(10 10 10 / 31%);
    -webkit-box-shadow: 2px 14px 10px -7px rgb(10 10 10 / 31%);
  }

  .cardContainer:hover {
    transform: unset;
    background-color: white;
  }

  .cardContainer:hover h4 {
    color: black;
  }

  .cardContainer:hover a {
    color: var(--gold);
  }

  .cardContainer:hover p {
    color: var(--strong-gray);
    opacity: 0.6;
  }
}
