.heroSection {
  width: 100%;
  position: absolute;
  top: 0;
}

.contactInfo {
  margin-top: 560px;
}

.mapBox {
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .contactInfo {
    margin-top: 390px;
  }
}
