.heroSection {
  height: 700px;
  width: 100%;
  position: absolute;
  top: 0;
}

.teamGrid {
  padding-top: 550px;
}

@media screen and (max-width: 991px) {
  .teamGrid {
    padding-top: 520px;
  }
}

@media screen and (max-width: 768px) {
  .teamGrid {
    padding-top: 450px;
  }
}
