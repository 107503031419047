.heroSection {
  width: 100%;
  position: absolute;
  top: 0;
}

.services {
  padding-top: 500px;
  padding-bottom: 50px;
}

@media screen and (max-width: 991px) {
  .services {
    padding-top: 480px;
  }
}

@media screen and (max-width: 768px) {
  .services {
    padding-top: 380px;
  }
}
