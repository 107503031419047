.navBarContainer {
  background: linear-gradient(90deg, rgb(42 48 149) 9%, rgb(76 81 155) 71%);
  opacity: 0.9;
  position: sticky;
  top: 0;
  z-index: 2;
  max-width: 1440px;
  margin-inline: auto;
  transition: var(--fast-transition);
}

.navBarContainerIsPinned {
  max-width: 100%;
}

.navBarContainer nav {
  padding: 25px 0px;
}

.navBarContainer ul {
  display: flex;
}

.navBarContainer ul li {
  margin-right: 64px;
  color: var(--nav-link-color);
  font-weight: 600;
  position: relative;
}

.navBarContainer ul li::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all var(--fast-transition) ease;
  background-color: var(--nav-link-color);
}

.navBarContainer ul li.active {
  color: var(--nav-link-color-active);
}

.navBarContainer ul li:hover {
  color: var(--nav-link-color-active);
  cursor: pointer;
}

.navBarContainer ul li:hover::after {
  width: 100%;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .navBarContainer {
    display: none;
  }
}

