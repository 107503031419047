.heroSection {
  position: absolute;
  top: 0;
  width: 100%;
}

.heroContainer {
  min-height: 700px;
  background-image: url("../../../Assets/images/bg-hero-home.jpg");
  background-size: cover;
  background-position: center;
  padding-top: calc(var(--nav-bar-height) + 50px);
}

.heroContent {
  padding: 52px;
  color: white;
  text-align: center;
}

.heroContent h1 {
  font-weight: 400;
  font-family: "Playfair Display", serif;
  font-size: 64px;
  position: relative;
  margin-bottom: 55px;
}

.heroContent h1::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 55px;
  height: 3px;
  background-color: white;
}

.heroContent p.subTitle {
  font-size: 18px;
  max-width: 750px;
  margin: auto;
  line-height: 35px;
}

@media screen and (max-width: 767px) {
  .heroContainer {
    min-height: 550px;
    padding-top: calc(var(--nav-bar-height) - 80px);
  }
}

@media screen and (max-width: 576px) {
  .heroContent {
    padding: 24px;
    color: white;
  }

  .heroContent h1 {
    font-size: 48px;
  }

  .heroContent p.subTitle {
    font-size: 16px;
    line-height: 30px;
  }
}

@media screen and (max-width: 330px) {
  .heroContent h1 {
    font-size: 34px;
  }
}
