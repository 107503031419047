.mainContainer {
  padding: 25px;
}

.innerContainer {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px;
  max-width: 300px;
  text-align: center;
  min-height: 300px;
}

.card .title {
  color: white;
  font-family: "Playfair Display", serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2.5px;
  margin-top: 15px;
}

.card .text {
  font-size: 17px;
  font-weight: 400;
  text-transform: none;
  color: #858585;
  line-height: 1.7;
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .innerContainer {
    flex-direction: column;
    padding: 5px;
  }

  .card {
    margin-bottom: 25px;
  }
}
