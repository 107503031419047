.teamGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-bottom: 80px;
}

.teamCard {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: white;
}

.teamCard img {
  height: 320px;
  width: 100%;
  object-fit: cover;
}

.teamCard .cardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}

.teamCard h4 {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1px;
  margin-bottom: 4px;
  font-family: "Playfair Display", serif;
}

.teamCard p {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 4px;
  color: #9d9d9d;
}

.teamCard a h5 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 4px;
}

@media screen and (max-width: 991px) {
  .teamGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 567px) {
  .teamGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
