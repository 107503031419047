/* --------------- FONTS */
/* Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Playfair Display */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Great Vibes */
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Variables */
:root {
  /* Color */
  --main-color: #4c51a0;
  --main-gray: #9d9d9d;
  --white: white;
  --main-black: #151e2c;
  --secondary-color: #b5bbe0;
  --secondary-bg-color: #3c3f6c;
  --light-gray: #bbbbbb;
  --strong-gray: #868686;
  --gold: #aa8d70;
  --blue: #3c3e6b;
  --gray: #d2d2d2;

  /* General */
  --border-radius: 5px;

  /* Main */
  --main-bg: #f8f8f8;

  /* NabBar */
  --nav-bar-height: 130px;
  --nav-bar-bg: #3c3e62;
  --nav-link-color: #eff0ff;
  --nav-link-color-active: white;
  --nav-bar-height: 232px;

  /* Transition */
  --fast-transition: 0.3s;
  --medium-transition: 0.6s;
  --slow-transition: 0.9s;
}

/* --------------- SCROLLBAR */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: rgba(0, 0, 0, 0);
  width: 2px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  background-color: var(--main-color);
  border-radius: 2px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* --------------- GENERAL SETTINGS */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* Scroll Bar */
  scrollbar-width: thin;
  scrollbar-color: rgb(222, 222, 222) !important;
}
html {
  scroll-behavior: smooth;
}

body,
input {
  overflow-y: overlay;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: var(--main-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.2;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}
h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

p {
  font-size: 15px;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

@media screen and (max-width: 991px) {
  h2 {
    font-size: 24px;
  }
}
