.mainContainer {
  padding-block: 10px;
}

.header {
  text-align: center;
  position: relative;
  margin-bottom: 95px;
}

.header h6 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--light-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.header svg {
  margin-left: 5px;
}

.header h3 {
  font-family: "Playfair Display", serif;
}

.header::after {
  content: "";
  position: absolute;
  bottom: -45px;
  left: 50%;
  transform: translateX(-50%) rotate(90deg);
  width: 55px;
  height: 2px;
  background-color: var(--gray);
}

.mainContainer p {
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  color: #555555;
}

.counterContainer {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.counter {
  text-align: center;
  background-image: url("../../../Assets/images/counter-bg-1.png");
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
}

.counter h4 {
  font-family: "Playfair Display", serif;
  font-weight: 500;
  font-size: 40px;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.counter h6 {
  font-size: 18px;
  font-weight: 400;
  color: #3a3e7f;
  padding-bottom: 40px;
}

@media screen and (max-width: 600px) {
  .counterContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
