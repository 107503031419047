.mainContainer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-block: 50px;
}

.mainContainer h2 {
  font-size: 28px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
  letter-spacing: 0px;
  text-transform: none;
  color: #b5bbe0;
  margin-bottom: 20px;
}

.faqItem {
  margin-bottom: 25px;
}

.faqItem h3 {
  font-size: 20px;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  letter-spacing: 0px;
  text-transform: none;
  color: white;
  cursor: pointer;
}

.titleContainer {
  display: flex;
  align-items: center;
}
.faqDesc {
  transition: all 0.4s ease;
  color: #b8b8b8;
  overflow: hidden;
  margin-top: 10px;
  padding-left: 32px;
  line-height: 1.7;
}
