.mainContainer {
  padding-block: 52px;
  background-color: #f3f3f3;
}

.header {
  text-align: center;
  position: relative;
  margin-bottom: 75px;
}

.header h6 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header svg {
  margin-left: 5px;
}

.header h3 {
  font-family: "Playfair Display", serif;
  margin-bottom: 15px;
}

.header::after {
  content: "";
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  width: 55px;
  height: 3px;
  background-color: var(--blue);
}

.teamCard {
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
  -moz-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.teamCard .imageContainer {
  height: 320px;
  position: relative;
  overflow: hidden;
}

.imageContainer img {
  height: 100%;
  width: 100%;
  transition: all 0.3s;
  object-fit: cover;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.imageContainer:hover img {
  transform: scale(1.1);
}

.teamCard .info {
  height: 125px;
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.teamCard .info h3 {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
  letter-spacing: 1px;
  font-family: "Playfair Display", serif;
  text-transform: uppercase;
}

.teamCard .info h6 {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #9d9d9d;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: initial;
  max-width: 95%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  color: #3a3e7f;
  font-size: 18px;
  font-weight: 600;
}

.pagination div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7e7e7;
  width: 60px;
  height: 60px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
