.mainContainer {
  padding-block: 10px;
}

.leftCol img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header {
  text-align: left;
  position: relative;
  margin-bottom: 75px;
}

.header h6 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--light-gray);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.header svg {
  margin-left: 5px;
}

.header h3 {
  font-family: "Playfair Display", serif;
}

.header::after {
  content: "";
  position: absolute;
  bottom: -35px;
  left: 29px;
  transform: translateX(-50%);
  width: 55px;
  height: 3px;
  background-color: var(--gray);
}

.mainDescription {
  font-size: 21px;
  font-weight: 400;
  text-transform: none;
  color: #3a3e7f;
  line-height: 1.7;
  margin-bottom: 20px;
}

.subDescription {
  font-size: 17px;
  font-weight: 400;
  text-transform: none;
  color: #858585;
  line-height: 1.7;
}

.rightCol a h6 {
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--blue);
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.rightCol a h6 svg {
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .leftCol {
    text-align: center;
  }
  .leftCol img {
    width: 80%;
    object-fit: cover;
    margin-bottom: 30px;
  }

  .header {
    text-align: center;
  }
  .header::after {
    left: 50%;
  }
  .header h6 {
    text-align: center;
    justify-content: center;
  }
}
